<template>
  <div class="login_container">
    <div>
      <h2>Swapifly后台管理系统</h2>
      <!-- :rules="rules" -->
      <el-form :model="formData">
        <el-form-item prop="userName">
          <el-input @keyup.enter.native="loginHandler" class="login_input" prefix-icon="el-icon-user" type="text" v-model="formData.userName" name="11"></el-input>
        </el-form-item>
        <el-form-item prop="pwd">
          <el-input @keyup.enter.native="loginHandler" class="login_input" show-password type="text" v-model="formData.pwd"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="loginHandler" :loading="loading">sign in</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import auth from '@/utils/auth'

export default {
  data() {
    return {
      rules: {
        username: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
      },
      loading: false,
      formData: {
        userName: '',
        pwd: '',
      },
    }
  },
  methods: {
    async login() {
      this.loading = true
      let res = await this.api.system.login(this.formData)
      this.loading = false
      // console.log(res)
      if (res && res.code == 0) {
        res.data.userName = this.formData.userName
        auth.set(res.data)
        let querys = this.$route.query
        if (querys && querys.redirect) {
          // 跳转到refer页面
          this.$router.push(querys.redirect)
        } else {
          this.$router.push('/home')
        }
      }
    },
    loginHandler() {
      this.login()
    },
  },
}
</script>
<style lang="scss" scoped>
.login_container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
}

.login_container > div {
  width: 550px;
  height: 350px;
  padding: 30px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login_container form {
  width: 100%;
}

.login_container h2 {
  margin-top: 10px;
  margin-bottom: 26px;
  color: #333;
  font-size: 30px;
}

.login_container .el-form-item__content .el-button {
  width: 100%;
}

.el-button {
  margin-top: 20px;
  height: 46px;
  font-size: 18px;
}
</style>
